import Archive from "../../components/archive/Archive"
import ArchiveGallery from "../../components/archive/ArchiveGallery"
import ArchiveVideo from "../../components/archive/ArchiveVideo"

export default function Festival2022() {
    const imagesDir = `${process.env.PUBLIC_URL}/assets/img/Festival2022/festival-2022-photo-`
    return (
        <Archive
            metaTitle="Festival IDAHOT 2022"
            metaDescription="Le Festival IDAHOT 2022 était la 10ème édition du festival LGBTQIA+ à Amiens ! Vous pouvez retrouver à l'occasion une vidéo faite par Flash Our True Colors."
            metaImage="https://fiertesamiens.fr/assets/img/Festival2022/festival-2022-photo-12.jpg"
            metaUrl="https://fiertesamiens.fr/archives/festival-idahot-2022"
            title="Festival IDAHOT"
            date="2022"
        >
            <ArchiveGallery>
                <div className="col-11 col-sm-11 col-md-6">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par <a href="https://www.simonducatez.fr" target="_blank" rel="noreferrer">DSim Photographe</a> en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}33.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}33.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}25.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}25.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}14.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}14.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}8.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}8.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                    </div>
                    <div className="row mb-4">
                        <a href={`${imagesDir}1.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}1.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}5.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}5.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}21.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}21.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}12.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}12.jpg`} alt="Rassemblement du Festival IDAHOT 2022 par DSim Photographe" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}3.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}9.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}13.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}15.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}17.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}18.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}22.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}23.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}27.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}31.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}32.jpg`}></div>
                </div>
            </ArchiveGallery>
            <ArchiveVideo
                videoTitle="Vidéo YouTube sur la 10ème édition du festival IDAHOT à Amiens"
                videoLink="https://www.youtube.com/embed/74e2DNxTvg8"
                videoHeight={500}
            >
                Vidéo de <a href="https://flash-our-true-colors.fr" target="_blank" rel="noreferrer">Flash Our True Colors</a>, association LGBTQIA+ amiénoise
            </ArchiveVideo>
        </Archive>
    )
}