import Archive from "../../components/archive/Archive"
import ArchiveGallery from "../../components/archive/ArchiveGallery"

export default function Pride2024() {
    const imagesDir = `${process.env.PUBLIC_URL}/assets/img/Marche2024/marche-2024-photo-`
    return (
        <Archive
            metaTitle="6ème Marche des Fiertés d'Amiens"
            metaDescription="La sixième Marche des Fiertés d'Amiens a eu lieu le 29 juin 2024, une pride très réussie avec un passage piéton arc-en-ciel !"
            metaImage="https://fiertesamiens.fr/assets/img/Marche2024/marche-2024-photo-1.jpg"
            metaUrl="https://fiertesamiens.fr/archives/marche-des-fiertes-2024"
            title="6ème Marche des Fiertés d'Amiens"
            date="29 juin 2024"
        >
            <ArchiveGallery>

                <div className="col-11 col-sm-11 col-md-6 mt-3">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par <a href="https://www.simonducatez.fr" target="_blank" rel="noreferrer">DSim Photographe</a> en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}simon-ducatez-4.jpg`} data-toggle="lightbox" data-gallery="simon-gallery" className="col-md-3">
                            <img src={`${imagesDir}simon-ducatez-4.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}simon-ducatez-23.jpg`} data-toggle="lightbox" data-gallery="simon-gallery" className="col-md-3">
                            <img src={`${imagesDir}simon-ducatez-23.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}simon-ducatez-82.jpg`} data-toggle="lightbox" data-gallery="simon-gallery" className="col-md-3">
                            <img src={`${imagesDir}simon-ducatez-82.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par DSim Photographe" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}simon-ducatez-121.jpg`} data-toggle="lightbox" data-gallery="simon-gallery" className="col-md-3">
                            <img src={`${imagesDir}simon-ducatez-121.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par DSim Photographe" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-1.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-3.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-5.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-9.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-12.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-13.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-14.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-15.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-17.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-18.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-21.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-22.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-25.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-27.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-31.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-32.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-33.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-34.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-35.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-36.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-37.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-38.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-39.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-40.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-41.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-42.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-43.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-44.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-45.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-46.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-47.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-48.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-49.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-50.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-51.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-52.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-53.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-54.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-55.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-56.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-57.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-58.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-59.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-60.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-61.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-62.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-63.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-64.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-65.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-66.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-67.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-68.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-69.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-70.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-71.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-72.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-73.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-74.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-75.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-76.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-77.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-78.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-79.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-80.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-81.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-83.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-84.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-85.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-86.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-87.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-88.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-89.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-90.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-91.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-92.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-93.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-94.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-95.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-96.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-97.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-98.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-99.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-100.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-101.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-102.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-103.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-104.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-105.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-106.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-107.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-108.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-109.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-110.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-111.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-112.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-113.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-114.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-115.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-116.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-117.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-118.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-119.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-120.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="simon-gallery" data-remote={`${imagesDir}simon-ducatez-122.jpg`}></div>
                </div>

                <div className="col-11 col-sm-11 col-md-6 mt-5">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par <a href="https://www.virginie-sueur.com/" target="_blank" rel="noreferrer">Virginie Sueur</a> en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}virginie-sueur-1.jpg`} data-toggle="lightbox" data-gallery="virginie-gallery" className="col-md-3">
                            <img src={`${imagesDir}virginie-sueur-1.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Virginie Sueur" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}virginie-sueur-22.jpg`} data-toggle="lightbox" data-gallery="virginie-gallery" className="col-md-3">
                            <img src={`${imagesDir}virginie-sueur-22.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Virginie Sueur" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}virginie-sueur-37.jpg`} data-toggle="lightbox" data-gallery="virginie-gallery" className="col-md-3">
                            <img src={`${imagesDir}virginie-sueur-37.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Virginie Sueur" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}virginie-sueur-46.jpg`} data-toggle="lightbox" data-gallery="virginie-gallery" className="col-md-3">
                            <img src={`${imagesDir}virginie-sueur-46.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Virginie Sueur" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-3.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-5.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-9.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-12.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-13.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-14.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-15.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-17.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-18.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-21.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-23.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-25.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-27.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-31.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-32.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-33.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-34.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-35.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-36.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-38.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-39.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-40.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-41.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-42.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-43.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-44.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-45.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-47.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-48.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-49.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-50.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-51.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-52.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-53.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-54.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-55.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-56.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-57.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-58.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-59.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-60.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-61.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-62.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-63.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-64.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-65.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-66.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-67.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-68.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-69.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-70.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="virginie-gallery" data-remote={`${imagesDir}virginie-sueur-71.jpg`}></div>
                </div>

                <div className="col-11 col-sm-11 col-md-6 mt-5">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par Cat Loy en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}cat-loy-3.jpg`} data-toggle="lightbox" data-gallery="cat-gallery" className="col-md-3">
                            <img src={`${imagesDir}cat-loy-3.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Cat Loy" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}cat-loy-15.jpg`} data-toggle="lightbox" data-gallery="cat-gallery" className="col-md-3">
                            <img src={`${imagesDir}cat-loy-15.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Cat Loy" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}cat-loy-31.jpg`} data-toggle="lightbox" data-gallery="cat-gallery" className="col-md-3">
                            <img src={`${imagesDir}cat-loy-31.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Cat Loy" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}cat-loy-32.jpg`} data-toggle="lightbox" data-gallery="cat-gallery" className="col-md-3">
                            <img src={`${imagesDir}cat-loy-32.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Cat Loy" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-1.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-5.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-9.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-12.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-13.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-14.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-17.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-18.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-21.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-22.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-23.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-25.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-27.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-33.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-34.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="cat-gallery" data-remote={`${imagesDir}cat-loy-35.jpg`}></div>
                </div>

                <div className="col-11 col-sm-11 col-md-6 mt-5">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par Martin Ligny en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}martin-ligny-1.jpg`} data-toggle="lightbox" data-gallery="martin-gallery" className="col-md-3">
                            <img src={`${imagesDir}martin-ligny-1.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Martin Ligny" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}martin-ligny-3.jpg`} data-toggle="lightbox" data-gallery="martin-gallery" className="col-md-3">
                            <img src={`${imagesDir}martin-ligny-3.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Martin Ligny" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}martin-ligny-9.jpg`} data-toggle="lightbox" data-gallery="martin-gallery" className="col-md-3">
                            <img src={`${imagesDir}martin-ligny-9.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Martin Ligny" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}martin-ligny-13.jpg`} data-toggle="lightbox" data-gallery="martin-gallery" className="col-md-3">
                            <img src={`${imagesDir}martin-ligny-13.jpg`} alt="Marche des Fiertés d'Amiens en 2024 par Martin Ligny" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-5.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-12.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="martin-gallery" data-remote={`${imagesDir}martin-ligny-14.jpg`}></div>
                </div>
            </ArchiveGallery>
        </Archive>
    )
}