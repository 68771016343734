import { Link } from "react-router-dom";
import logo from '../../assets/logos/logo-fiertes-amiens.jpg'

export default function Header() {
  return (
    <header className="row" style={{ marginBottom: "5rem" }}>
      <div className="col-12 col-sm-12 col-lg-12">
        <nav className="navbar navbar-expand-lg p-3 navbar-white fixed-top shadow">
          <Link className="navbar-brand" to="/" id="top">
            <img src={logo} width="40" height="40" className="d-inline-block ml-3" alt="Logo de Fiertés Amiens"/>
          </Link>
          <button
            className="navbar-toggler burger"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"><i className="fas fa-plus-square fa-lg mt-2 orange"></i></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item pr-4 pl-4">
                <Link className="nav-link fontGlobale colorPride4" to="/marche-des-fiertes-2024">6ème Marche des Fiertés</Link>
              </li>
              <li className="nav-item pr-4 pl-4">
                <Link className="nav-link fontGlobale orange" to="/festival-2024">Festival 2024</Link>
              </li>
              <li className="nav-item pr-4 pl-4">
                <Link className="nav-link fontGlobale blue" to="/archives">Archives</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}