function EventCard2023({data}) {
    return (
        <div className="col row m-0 mb-3">
            <div>
                {data.img !== undefined && <img src={data.img} className="img-fluid mb-3" alt={data.imgAlt}/>}
                <div className="d-flex">
                    <h4 style={{fontWeight:"bold"}}>{data.name}</h4>
                    {data?.payable && <i className="fas fa-euro-sign fa-lg mt-1 ml-3"></i>}
                    {data?.registration && <i className="fas fa-user-edit fa-lg mt-1 ml-3"></i>}
                </div>
                <p className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i>{data.place}</p>
                <p className="mb-2"><i className="far fa-calendar-alt mr-2"></i>{data.schedule}</p>
                <p>{data.description}</p>
            </div>
        </div>
    )
}

function EventCard2024({data}) {
    return (
        <div className="col row m-0 mb-3">
            <div className="row">
                <div className="col-6">
                    {data.img !== undefined && <img src={data.img} className="img-fluid mb-3" alt={data.imgAlt}/>}
                    <h4 style={{fontWeight:"bold"}}>{data.name}</h4>
                    <p className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i>{data.place}</p>
                    <p className="mb-2"><i className="far fa-calendar-alt mr-2"></i>{data.schedule}</p>
                    {data?.payable && <p className="mb-2"><i className="fas fa-euro-sign mr-2"></i>{data?.payable}</p>}
                    {data?.registration && <p className="mb-2"><i className="fas fa-user-edit mr-2"></i>{data?.registration}</p>}
                </div>
                <div className="col-6">
                    <p>{data.description}</p>
                </div>
            </div>
        </div>
    )
}

export function EventsCard2023({data}) {
    return (
        <>
            <h3 className="mb-4" style={{backgroundColor: data.color, color:"white", padding: "10px"}}>{data.date}</h3>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-3 px-3">
                {data.events.map((e, index) => <EventCard2023 data={e} key={index}/>)}
            </div>
        </>
    )
}

export function EventsCard2024({data}) {
    return (
        <>
            <h3 className="mb-4" style={{backgroundColor: data.color, color:"white", padding: "10px"}}>{data.date}</h3>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-3 px-3">
                {data.events.map((e, index) => <EventCard2024 data={e} key={index}/>)}
            </div>
        </>
    )
}