import Archive from "../../components/archive/Archive"
import ArchiveGallery from "../../components/archive/ArchiveGallery"
import ArchiveVideo from "../../components/archive/ArchiveVideo"

export default function Festival2024() {
    const imagesDir = `${process.env.PUBLIC_URL}/assets/img/Festival2024/festival-2024-photo-`
    return (
        <Archive
            metaTitle="Festival Hauts⸱en⸱Couleur (IDAHOT) 2024"
            metaDescription="Le Festival IDAHOT 2024 était la 12ème édition du festival LGBTQIA+ à Amiens ! Vous pouvez retrouver à l'occasion une vidéo faite par Flash Our True Colors."
            metaImage="https://fiertesamiens.fr/assets/img/Festival2024/festival-2024-photo-37.jpg"
            metaUrl="https://fiertesamiens.fr/archives/festival-hauts-en-couleur-2024"
            title="Festival Hauts⸱en⸱Couleur (IDAHOT)"
            date="2024"
        >
            <ArchiveGallery>
                <div className="col-11 col-sm-11 col-md-6">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}1.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}1.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}9.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}9.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}13.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}13.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}17.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}17.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                    </div>
                    <div className="row mb-4">
                        <a href={`${imagesDir}42.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}42.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}73.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}73.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}109.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}109.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}117.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}117.jpg`} alt="Festival Hauts en Couleur IDAHOT 2024" className="img-fluid" />
                        </a>
                    </div>
                    {/* Images : 15a, 24a, 25a, 26a, 27a, 28a, 29a, 30b, 31b, 32a, 33a, 34a, 35c, 36c, 37c, 38c, 39c, 40c, 41c, 44a, 47a, 48a, 49a, 50a, 51a, 52a, 53a, 54a, 55a, 56a, 57a, 58a, 59a, 60a, 77a, 79a, 81a, 103a, 120a, 125a, 131a, 132a, 133a, 134a */}
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}2.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}3.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}5.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}6.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}7.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}14.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}15.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}21.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}22.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}23.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}25.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}31.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}32.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}33.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}34.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}35.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}36.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}38.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}39.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}40.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}41.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}43.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}44.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}45.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}46.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}47.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}48.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}49.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}50.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}51.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}52.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}53.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}54.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}55.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}56.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}57.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}58.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}59.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}60.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}61.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}62.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}63.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}64.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}65.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}66.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}67.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}68.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}69.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}70.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}71.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}72.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}74.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}76.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}77.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}78.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}79.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}80.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}81.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}82.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}83.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}84.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}85.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}86.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}87.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}88.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}89.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}90.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}91.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}92.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}93.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}94.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}95.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}97.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}98.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}99.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}100.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}101.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}102.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}103.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}104.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}105.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}106.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}107.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}108.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}110.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}111.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}112.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}113.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}114.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}115.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}116.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}118.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}119.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}120.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}121.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}122.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}123.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}124.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}125.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}126.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}127.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}128.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}129.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}130.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}131.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}132.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}133.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}134.jpg`}></div>
                </div>
            </ArchiveGallery>
            <ArchiveVideo
                videoTitle="Vidéo rétrospective sur le festival LGBTQIA+ Hauts en Couleur par l'association Flash Our True Colors"
                videoLink="https://www.youtube.com/embed/y6Cuf5Q-GV8"
                videoHeight={500}
            >
                Vidéo de <a href="https://flash-our-true-colors.fr" target="_blank" rel="noreferrer">Flash Our True Colors</a>, association LGBTQIA+ amiénoise
            </ArchiveVideo>
        </Archive>
    )
}