import { Link } from "react-router-dom";

export default function ThemeCard({imageSrc, imageAlt, title, description, link}) {
    return (
        <div className="col mb-4">
            <div className="card">
                <img src={imageSrc} alt={imageAlt} style={{maxHeight: "500px", objectFit: "cover"}}/>
                <div className="card-body">
                    <h3>{title}</h3>
                    <p className="thasadith">{description}</p>
                    <div className="d-flex justify-content-center mt-4">
                        <Link to={link}>
                            <button className="btn border-rainbow">
                                En savoir plus
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}