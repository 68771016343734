import { Helmet } from "react-helmet"

import ThemeCard from "../components/ThemeCard"

import coverPride from '../assets/home/cover-pride.jpg'
import coverFestival from '../assets/home/cover-festival.jpg'
import coverArchives from '../assets/home/cover-archives.jpg'

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Fiertés Amiens</title>
                <meta name="description" content="Retrouvez toutes les informations sur la Marche des Fiertés d'Amiens dont la 6ème édition aura lieu le samedi 29 juin 2024 !"/>
            </Helmet>

            <section className="row justify-content-center pt-3 pb-4">
                <div className="col-11 col-sm-11 col-md-6 pt-4 pb-2">
                <h1 className="text-center mb-2">
                    <span style={{color: "#E40303"}}>Fi</span>
                    <span style={{color: "#FF8C00"}}>er</span>
                    <span style={{color: "#F3C621"}}>tés </span>
                    <span style={{color: "#008026"}}>Am</span>
                    <span style={{color: "#004DFF"}}>ie</span>
                    <span style={{color: "#750787"}}>ns</span>
                </h1>
                <p className="text-center mb-4 pt-2 thasadith">Événements associatifs LGBTQIA+ à Amiens</p>
                </div>
                <div className="col-11 col-md-10">
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-3 row-cols-xl-3">
                        <ThemeCard
                            imageSrc={coverPride}
                            imageAlt="Photographie de Simon Ducatez de la Marche des Fiertés d'Amiens 2024"
                            title="6ème Pride d'Amiens"
                            description="Retrouve ici toutes les informations sur l'organisation de la Marche des Fiertés d'Amiens !"
                            link="/marche-des-fiertes-2024"
                        />
                        <ThemeCard
                            imageSrc={coverFestival}
                            imageAlt="Photographie de Simon Ducatez du Festival LGBTQIA+ d'Amiens"
                            title="Festival Hauts en Couleur 2024"
                            description="Viens découvrir le Festival Hauts en Couleur qui célèbre le 17 mai : journée mondiale contre les LGBTQIA+phobies"
                            link="/festival-2024"
                        />
                        <ThemeCard
                            imageSrc={coverArchives}
                            imageAlt="Photographie de Juliette Fabijan de la première Marche des Fiertés d'Amiens prise depuis un toit"
                            title="Archives"
                            description="Pour voir les archives photos et vidéos des Festivals LGBTQIA+ et Marches des Fiertés à Amiens"
                            link="/archives"
                        />
                    </div>
                </div>
            </section>
        </>
    )
}