import { Helmet } from "react-helmet"

import ArchivesCard from "../components/ArchivesCard"

import AfficheMarche2019 from '../assets/archives/affiche-marche-des-fiertes-amiens-2019.jpg'
import AfficheMarche2020 from '../assets/archives/affiche-marche-des-fiertes-amiens-2020.jpg'
import AfficheMarche2021 from '../assets/archives/affiche-marche-des-fiertes-amiens-2021.jpg'
import AfficheFestival2022 from '../assets/archives/affiche-festival-idahot-amiens-2022.jpg'
import AfficheMarche2022 from '../assets/archives/affiche-marche-des-fiertes-amiens-2022.jpg'
import AfficheFestival2023 from '../assets/archives/affiche-festival-hauts-en-couleur-amiens-2023.jpg'
import AfficheMarche2023 from '../assets/archives/affiche-marche-des-fiertes-amiens-2023.jpg'
import AfficheFestival2024 from '../assets/archives/affiche-festival-hauts-en-couleur-amiens-2024.jpg'
import AfficheMarche2024 from '../assets/archives/affiche-marche-des-fiertes-amiens-2024.jpg'

export default function Archives() {
    return (
        <div className="row justify-content-center pt-5 pb-4">
            <Helmet>
                <title>Archives</title>
                <meta name="description" content="Les archives du Festival IDAHOT et de la Marche des Fiertés d'Amiens contiennent les affiches, les photos et les vidéos des années précédentes."/>
            </Helmet>
            <div className="col-11 col-sm-11 col-md-6">
                <h1 className="text-center mb-2 blue">Archives de Fiertés Amiens</h1>
                <p className="text-center mb-4 pt-2 thasadith">Retrouvez ici les archives des Marches des Fiertés ainsi que des Festivals</p>
            </div>
            <div className="col-11 col-sm-11">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                    <ArchivesCard 
                        imageAlt="Affiche de la 6ème Marche des Fiertés d'Amiens le samedi 29 juin 2024"
                        imageSrc={AfficheMarche2024}
                        title="6ème Marche des Fiertés d'Amiens"
                        link="/archives/marche-des-fiertes-2024"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche du Festival Hauts en Couleur 2024 à Amiens"
                        imageSrc={AfficheFestival2024}
                        title="Festival Hauts en Couleur 2024 à Amiens"
                        link="/archives/festival-hauts-en-couleur-2024"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche de la 5ème Marche des Fiertés d'Amiens le 1 juillet 2023"
                        imageSrc={AfficheMarche2023}
                        title="5ème Marche des Fiertés d'Amiens"
                        link="/archives/marche-des-fiertes-2023"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche du Festival Hauts en Couleur 2023 à Amiens"
                        imageSrc={AfficheFestival2023}
                        title="Festival Hauts en Couleur 2023 à Amiens"
                        link="/archives/festival-hauts-en-couleur-2023"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche de la 4ème Marche des Fiertés d'Amiens le 2 juillet 2022"
                        imageSrc={AfficheMarche2022}
                        title="4ème Marche des Fiertés d'Amiens"
                        link="/archives/marche-des-fiertes-2022"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche du Festival Idahot 2022 à Amiens"
                        imageSrc={AfficheFestival2022}
                        title="Festival IDAHOT Amiens 2022"
                        link="/archives/festival-idahot-2022"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche de la 3ème Marche des Fiertés d'Amiens le 19 juin 2021"
                        imageSrc={AfficheMarche2021}
                        title="3ème Marche des Fiertés d'Amiens"
                        link="/archives/marche-des-fiertes-2021"
                        button={true}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche de la 2ème Marche des Fiertés d'Amiens le 19 septembre 2020"
                        imageSrc={AfficheMarche2020}
                        title="2ème Marche des Fiertés d'Amiens"
                        link="/archives/marche-des-fiertes-2020"
                        button={false}
                    />
                    <ArchivesCard 
                        imageAlt="Affiche de la 1ère Marche des Fiertés d'Amiens le 22 juin 2019"
                        imageSrc={AfficheMarche2019}
                        title="1ère Marche des Fiertés d'Amiens"
                        link="marche-des-fiertes-2019"
                        button={true}
                    />
                </div>
            </div>
        </div>
    )
}