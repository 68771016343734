import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="row pt-4 block-light shadow-lg">
      <div className="col-md-4 col-sm-12 pb-3">
        <div className="d-flex justify-content-center">
          <Link to="mentions-legales" style={{fontFamily:'Fredericka the Great', fontSize:"1.3em", color:"#ec1940"}}>
            Mentions légales
          </Link>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 pb-3">
        <div className="d-flex justify-content-center">
          <p className="text-center">Fiertés Amiens © 2019-{new Date().getFullYear()}. Tous droits réservés.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 pb-3">
        <div className="d-flex justify-content-center">
          <a href="mailto:contact@asso-fotc.fr" target="_blank" rel="noreferrer">
            <i className="fas fa-envelope fa-2x mr-3" style={{color:"#c0c0c0"}}></i>
          </a>
          <a href="https://facebook.com/fiertesamiens/" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-square fa-2x mr-3" style={{color:"#475993"}}></i>
          </a>
          <a href="https://instagram.com/fiertesamiens/" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram-square fa-2x mr-3" style={{color:"#dd2a7b"}}></i>
          </a>
        </div>
      </div>
    </footer>
  )
}
