export default function ArchiveVideo({ videoTitle, videoLink, videoHeight, children }) {
    return (
        <section className="row justify-content-center mt-4">
            <div className="col-11 col-sm-11 col-md-6">
                <h2 className="text-center mb-2 blue">Retour en vidéo</h2>
                <p className="text-center mb-4 pt-2 thasadith">
                    {children}
                    <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                </p>
                <iframe
                    title={videoTitle}
                    width="100%"
                    height={videoHeight}
                    src={videoLink}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </section>
    )
}