import { Helmet } from "react-helmet";

export default function Archive({ metaTitle, metaDescription, metaImage, metaUrl, title, date, children }) {
    return (
        <div className="pt-5 pb-5">
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:image" content={metaImage}/>
                <meta property="og:url" content={metaUrl}/>
                <meta property="og:description" content={metaDescription}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:image" content={metaImage}/>
            </Helmet>
            <div className="row justify-content-center">
                <div className="col-11 col-sm-11 col-md-6">
                    <h1 className="text-center mb-2 blue">{title} <br /> {date}</h1>
                </div>
            </div>
            {children}
        </div>
    )
}