import { Link } from "react-router-dom";

export default function ArchivesCard({imageSrc, imageAlt, title, link, button}) {
    return (
        <div className="col mb-4">
            <div className="card">
                <img src={imageSrc} alt={imageAlt}/>
                <div className="card-body">
                    <h3>{title}</h3>
                    {button &&
                        <div className="d-flex justify-content-center mt-4">
                            <Link to={link}>
                                <button className="btn border-rainbow">
                                    Archives
                                </button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}