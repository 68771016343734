import { Routes, Route, Navigate } from 'react-router-dom';

// COMPONENTS
import Header from './components/navigation/Header';
import Footer from './components/navigation/Footer';

// BASE PAGES
import Mentions from './views/Mentions';
import NoMatch from './views/NoMatch';
import Archives from './views/Archives';
import Home from './views/Home';

// PRIDE & FESTIVAL
import PrideInfo2023 from './views/prides/PrideInfo2023';
import PrideInfo2024 from './views/prides/PrideInfo2024';
import FestivalInfo2023 from './views/festivals/FestivalInfo2023';
import FestivalInfo2024 from './views/festivals/FestivalInfo2024';

// ARCHIVES
import Pride2019 from './views/archives/Pride2019';
import Pride2021 from './views/archives/Pride2021';
import Festival2022 from './views/archives/Festival2022';
import Pride2022 from './views/archives/Pride2022';
import Festival2023 from './views/archives/Festival2023';
import Pride2023 from './views/archives/Pride2023';
import Festival2024 from './views/archives/Festival2024';
import Pride2024 from './views/archives/Pride2024';

function App() {
  return (
    <div className="container-fluid">
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="archives">
          <Route index element={<Archives />} />
          <Route path="marche-des-fiertes-2023" element={<Pride2023 />} />
          <Route path="marche-des-fiertes-2024" element={<Pride2024 />} />
          <Route path="festival-hauts-en-couleur-2023" element={<Festival2023 />} />
          <Route path="festival-hauts-en-couleur-2024" element={<Festival2024 />} />
          <Route path="marche-des-fiertes-2022" element={<Pride2022 />} />
          <Route path="festival-idahot-2022" element={<Festival2022 />} />
          <Route path="marche-des-fiertes-2021" element={<Pride2021 />} />
          <Route path="marche-des-fiertes-2019" element={<Pride2019 />} />
        </Route>
        <Route path="mentions-legales" element={<Mentions />} />
        <Route path="marche-des-fiertes-2023" element={<PrideInfo2023/>} />
        <Route path="marche-des-fiertes-2024" element={<PrideInfo2024/>} />
        <Route path="festival-2023" element={<FestivalInfo2023 />} />
        <Route path="festival-2024" element={<FestivalInfo2024 />} />

        {/* REDIRECTIONS */}
        <Route path="index.php" element={<Navigate to="/"/>} />
        <Route path="collectif.php" element={<Navigate to="/"/>} />
        <Route path="archives.php" element={<Navigate to="/archives"/>} />
        <Route path="mentions.php" element={<Navigate to="/mentions-legales"/>} />
        <Route path="politique.php" element={<Navigate to="/mentions-legales"/>} />
        <Route path="marche-des-fiertes" element={<Navigate to="/marche-des-fiertes-2024"/>} />
        <Route path="festival" element={<Navigate to="/festival-2024"/>} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
