const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24

const countDown = new Date('June 29, 2024 14:00:00').getTime()

export function getTimeRemaining() {
    const distance = countDown - new Date().getTime()
    return {
        days: Math.floor(distance / (day)),
        hours: Math.floor((distance % (day)) / (hour)),
        minutes: Math.floor((distance % (hour)) / (minute)),
        seconds: Math.floor((distance % (minute)) / second)
    }
}