import Archive from "../../components/archive/Archive"
import ArchiveVideo from "../../components/archive/ArchiveVideo"
import ArchiveGallery from "../../components/archive/ArchiveGallery"

export default function Pride2019() {
    const imagesDir = `${process.env.PUBLIC_URL}/assets/img/Marche2019/marche-2019-photo-`
    return (
        <Archive
            metaTitle="1ère Marche des Fiertés d'Amiens"
            metaDescription="La première Marche des Fiertés d'Amiens a eu lieu le 22 juin 2019, c'était également l'anniversaire des 50 ans des émeutes de Stonewall."
            metaImage="https://fiertesamiens.fr/assets/img/Marche2019/marche-2019-photo-9.jpg"
            metaUrl="https://fiertesamiens.fr/archives/marche-des-fiertes-2019"
            title="1ère Marche des Fiertés d'Amiens"
            date="22 juin 2019"
        >
            <ArchiveGallery>
                <div className="col-11 col-sm-11 col-md-6">
                    <p className="text-center mb-4 pt-2 thasadith">
                        Photos exclusives par <a href="https://www.juliettefabijan.fr/" target="_blank" rel="noreferrer">Juliette Fabijan</a> en cliquant sur l'une des images
                        <i className="fas fa-angle-double-down fa-lg orange ml-2"></i>
                    </p>
                </div>
                <div className="col-11 col-sm-11">
                    <div className="row">
                        <a href={`${imagesDir}124.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}124.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}9.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}9.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}6.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}6.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}7.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}7.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                    </div>
                    <div className="row mb-4">
                        <a href={`${imagesDir}1.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}1.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}5.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}5.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}3.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}3.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                        <a href={`${imagesDir}2.jpg`} data-toggle="lightbox" data-gallery="example-gallery" className="col-md-3">
                            <img src={`${imagesDir}2.jpg`} alt="Marche des fiertés d'Amiens en 2019 par Juliette Fabijan" className="img-fluid" />
                        </a>
                    </div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}4.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}8.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}10.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}11.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}12.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}13.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}14.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}15.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}16.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}17.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}18.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}19.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}20.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}21.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}22.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}23.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}24.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}25.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}26.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}27.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}28.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}29.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}30.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}31.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}32.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}33.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}34.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}35.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}36.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}37.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}38.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}39.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}40.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}41.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}42.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}43.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}44.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}45.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}46.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}47.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}48.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}49.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}50.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}51.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}52.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}53.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}54.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}55.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}56.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}57.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}58.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}59.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}60.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}61.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}62.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}63.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}64.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}65.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}66.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}67.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}68.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}69.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}70.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}71.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}72.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}73.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}74.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}75.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}76.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}77.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}78.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}79.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}80.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}81.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}82.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}83.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}84.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}85.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}86.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}87.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}88.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}89.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}90.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}91.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}92.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}93.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}94.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}95.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}96.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}97.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}98.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}99.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}100.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}101.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}102.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}103.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}104.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}105.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}106.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}107.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}108.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}109.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}110.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}111.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}112.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}113.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}114.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}115.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}116.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}117.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}118.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}119.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}120.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}121.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}122.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}123.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}125.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}126.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}127.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}128.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}129.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}130.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}131.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}132.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}133.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}134.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}135.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}136.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}137.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}138.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}139.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}140.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}141.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}142.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}143.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}144.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}145.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}146.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}147.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}148.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}149.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}150.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}151.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}152.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}153.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}154.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}155.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}156.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}157.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}158.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}159.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}160.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}161.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}162.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}163.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}164.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}165.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}166.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}167.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}168.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}169.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}170.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}171.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}172.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}173.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}174.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}175.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}176.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}177.jpg`}></div>
                    <div data-toggle="lightbox" data-gallery="example-gallery" data-remote={`${imagesDir}178.jpg`}></div>
                </div>
            </ArchiveGallery>
            <ArchiveVideo
                videoTitle="Vidéo YouTube sur la 1ère marche des fiertés d'Amiens le 22 juin 2019"
                videoLink="https://www.youtube.com/embed/NtttY9GAaAg"
                videoHeight="400"
            >
                Vidéo des Bavardes, collectif féministe et lesbien
            </ArchiveVideo>
        </Archive>
    )
}