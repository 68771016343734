import { Helmet } from "react-helmet"

export default function Mentions() {
    return (
        <div className="col-lg-12 pt-5">
            <Helmet>
                <title>Mentions légales</title>
                <meta name="description" content="Le site Fiertés Amiens informe sur la Marche des Fiertés d'Amiens et le Festival IDAHOT, il est édité par l'association Flash Our True Colors."/>
            </Helmet>
            <div className="container">
                <div className="row shadow mb-5 rgpd">
                    <div className="col-12 col-md-12 rainbow mb-5"></div>
                    <div className="col-md-12">
                        <h1 className="text-center">Mentions légales</h1>
                        <h2 className="pt-4 pb-3">Conception du site internet :</h2>
                        <p>
                            Timothée Kunde : aide à la rédaction<br />
                            Louiza Tasse : réalisation du logo<br />
                            Innocent Binyoma : développement et mise à jour<br />
                            <a href="https://www.linkedin.com/in/marionhelenejoly/" target="_blank" rel="noreferrer">Marion Joly</a> : conception, rédaction, développement et design<br/>
                            <a href="https://louissachy.fr" target="_blank" rel="noreferrer">Louis Sachy</a> : refonte, rédaction, développement et maintenance<br/><br/>
                            Nous les remercions tous.tes pour leur travail.
                        </p>
                        <h2 className="pt-3 pb-3">Hébergement :</h2>
                        <p>
                            L’hébergement du site est assuré par OVH SAS<br />
                            Adresse : 2 rue Kellermann, 59100, Roubaix, France<br />
                            RCS Lille Métropole : 424 761 419 00045<br />
                            Code APE : 6202A<br />
                            N° TVA : FR 22 424 761 419<br />
                            Dirigeant légal : Octave KLABA<br />
                        </p>
                        <h2 className="pt-3 pb-3">Édition</h2>
                        <p>
                            Le site est édité par <a href="https://flash-our-true-colors.fr" target="_blank" rel="noreferrer">Flash Our True Colors</a> (association de loi 1901)<br/>
                            Adresse : c/o 12 rue Frédéric Petit, 80000, Amiens, France<br/>
                            Courriel : contact@asso-fotc.fr
                        </p>
                        <h2 className="pt-3 pb-3">Propriété intellectuelle :</h2>
                        <p>
                            Ce site demeure et reste une création de l’esprit protégé par la loi. Tout le contenu du site FiertesAmiens.fr est strictement privé et réservé au titre du droit d’auteur ainsi qu’au titre de la
                            propriété intellectuelle.<br />
                            Tous les droits de reproduction du site FiertesAmiens.fr sont donc strictement privés et réservés.<br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}