import { Helmet } from "react-helmet";

export default function ArchiveGallery({ children }) {
    return (
        <section>
            <Helmet>
                {/* Ekko Lightbox */}
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.css" />
                <script src="https://cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.min.js"></script>
                <script>{`
                    $(document).on('click', '[data-toggle="lightbox"]', function(event){
                        event.preventDefault();
                        $(this).ekkoLightbox();
                    })
                `}</script>
            </Helmet>
            <div className="row justify-content-center">
                <div className="col-11 col-sm-11 col-md-6">
                    <h2 className="text-center mt-5 mb-2 blue">Retour en images</h2>
                </div>
            </div>
            <div className="row justify-content-center">
                {children}
            </div>
        </section>
    )
}