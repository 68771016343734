import villageAssociatif from './assets/festival/2023/village-associatif-citadelle.png'
import soireeInauguration from './assets/festival/2023/soiree-inauguration-cote-jardin.png'
import cineLicorneTheQueen from './assets/festival/2023/cine-licorne-the-queen.png'
import atelierDrapeau from './assets/festival/2023/atelier-mon-genre-a-moi.png'
import rassemblement from './assets/festival/2023/rassemblement-lgbtqiaphobies-hotel-de-ville-amiens.png'
import atelierSublimer from './assets/festival/2023/atelier-sublimer-le-mouvement.png'
import atelierPratiqueArtistique from './assets/festival/2023/atelier-pratique-artistique-vir-andes-hera.png'
import projectionEtRencontre from './assets/festival/2023/projection-et-rencontre-le-daftar-vir-andres-hera.png'
import atelierGlowUp from './assets/festival/2023/atelier-glow-up-bal-des-couleurs.png'

import villageAssoUPJV from './assets/festival/2024/village-associatif-pole-universitaire-cathedrale-upjv-amiens-2024.png'
import soireeInauguration2024 from './assets/festival/2024/soiree-d-inauguration-festival-hauts-en-couleur-2024-cote-jardin.png'
import rassemblementRevendicatif from './assets/festival/2024/rassemblement-lgbt-amiens-hotel-de-ville-17-mai.png'
import soireeBalDesCouleurs from './assets/festival/2024/soiree-bal-des-couleurs-lgbt-cite-carter-2024-amiens.png'
import atelierDragPoetry from './assets/festival/2024/atelier-drag-poetry-association-trans-divergenre-amiens.png'
import conferenceSport from './assets/festival/2024/conference-sport-et-personnes-lgbtqia-amiens-2024-federation-sportive-lgbt.png'
import cineDebatRafiki from './assets/festival/2024/cine-debat-rafiki-fifam-cinema-orson-welles.png'
import standCrous from './assets/festival/2024/stand-flash-our-true-colors-association-lgbt-aupres-d-etudiants-a-amiens.png'
import atelierOpprime from './assets/festival/2024/association-pas-a-passo-atelier-theatre-de-l-opprime-amiens.png'

import flashourtruecolors from './assets/festival/2024/partenaires/flash-our-true-colors.png'
import federationsportivelgbt from './assets/festival/2024/partenaires/federation-sportive-lgbt.png'
import divergenre from './assets/festival/2024/partenaires/divergenre.png'
import pasapasso from './assets/festival/2024/partenaires/pas-a-passo-theatre-de-l-opprime.png'
import citecarter from './assets/festival/2024/partenaires/cite-carter.png'
import fifam from './assets/festival/2024/partenaires/festival-international-du-film-d-amiens.png'
import maisondelaculture from './assets/festival/2024/partenaires/maison-de-la-culture-amiens.png'
import upjv from './assets/festival/2024/partenaires/universite-picardie-jules-verne.png'
import crous from './assets/festival/2024/partenaires/crous-amiens-picardie.png'
import republiquefrancaise from './assets/festival/2024/partenaires/republique-francaise.png'
import villeamiens from './assets/festival/2024/partenaires/ville-amiens.png'

export const programmation2023 = [
    {
        date: "Lundi 15 mai",
        color: "#e40303",
        events: [
            {
                name: "🎪 Village associatif",
                description: "En partenariat avec l'Université Picardie Jules Verne, viens rencontrer les acteur-trice-s du territoire qui luttent contre les discriminations faites aux personnes LGBTQIA+ et aux femmes.",
                place: "La Citadelle, UPJV, rue des Français Libres, Amiens",
                schedule: "11h - 17h",
                img: villageAssociatif,
                imgAlt: "Village associatif de l'UPJV pendant le festival IDAHOT"
            },
            {
                name: "🎈 Soirée d'inauguration",
                description: "Viens nous rencontrer lors de la soirée d'inauguration du festival. Au programme : rencontre avec les associations, présentation de la programmation du festival, découvertes et échanges.",
                place: "Côté Jardin, 2 place Léon Gontier, Amiens",
                schedule: "19h - 21h",
                img: soireeInauguration,
                imgAlt: "Soirée d'inauguration à Côté Jardin pour le festival IDAHOT d'Amiens"
            }
        ]
    },
    {
        date: "Mardi 16 mai",
        color: "#ff8c00",
        events: [
            {
                name: "🎥🦄 Ciné-Licorne : The Queen",
                description: "Jack 24 ans, parfois une drag queen nommée Sabrina. Sous ce nom, en 1967, il est maitresse de cérémonie d'un concours national de drag queen à New York. Film de Frank Simon 1967 (tarifs du Ciné St-Leu).",
                place: "Ciné St-Leu, 33 Rue Vanmarcke, Amiens",
                schedule: "18h30",
                img: cineLicorneTheQueen,
                imgAlt: "Film The Queen au Festival IDAHOT avec le Ciné St-Leu",
                payable: true
            }
        ]
    },
    {
        date: "Mercredi 17 mai",
        color: "#F2D813",
        events: [
            {
                name: "🏳️🎨 Atelier : Mon genre à moi",
                description: "Ton pays, tes origines, ton orientation sexuelle ou romantique, ton genre, ton caractère, ton plat préféré... Tu es une personne unique, qui ressent le monde qui l'entoure de façon singulière. Viens explorer ton rapport intime a notre société à travers la création de ton propre drapeau ! Vous pouvez aussi venir faire des pancartes pour le rassemblement 📝 Sur inscription : divergenre80@gmail.com",
                place: "Boîte Sans Projet, 12 rue Florimond Leroux, Amiens",
                schedule: "14h - 17h",
                img: atelierDrapeau,
                imgAlt: "Drapeaux LGBTQIA+, atelier de Divergenre",
                registration: true
            },
            {
                name: "🎤🏳️‍🌈 Rassemblement contre les LGBTQIA+phobies",
                description: "Dans le cadre du festival, du 15 au 20 mai, nous invitons la population à nous rejoindre pour manifester son soutien. Venez avec nous au rassemblement, ensemble disons STOP aux agressions LGBTQIA+phobes !",
                place: "Place de l'Hôtel de Ville, Amiens",
                schedule: "18h - 19h",
                img: rassemblement,
                imgAlt: "Rassemblement contre les LGBTQIA+phobies du festival IDAHOT",
            }
        ]
    },
    {
        date: "Jeudi 18 mai",
        color: "#008026",
        events: [
            {
                name: "💃🕺 Atelier : Sublimer le mouvement",
                description: "Un atelier pour expérimenter le mouvement dans son corps et dans l'espace. Explorons ce qu'est le féminin, le masculin, le pouvoir ou la douceur dans nos corps 📝 Sur inscription : flash.our.true.colors@gmail.com",
                place: "Social Club, Shopping Promenade, 150 avenue de l'Europe, Amiens",
                schedule: "17h - 19h",
                registration: true,
                img: atelierSublimer,
                imgAlt: "Illustration d'un atelier d'expression corporelle par l'association Flash Our True Colors",
            }
        ]
    },
    {
        date: "Vendredi 19 mai",
        color: "#004dff",
        events: [
            {
                name: "[Annulé] 🎭 Atelier de pratique artistique",
                description: "Atelier de pratique artistique autour des usages poétiques, romantiques et amoureux des langues mélées. Dans la lignée des travaux que mène Vir Andres Hera.",
                place: "ESAD, 40 rue des Teinturiers, Amiens",
                schedule: "13h - 16h",
                img: atelierPratiqueArtistique,
                imgAlt: "Vir Andres Hera, artiste mexicain qui anime un atelier de pratique artistique",
            },
            {
                name: "[Annulé] 🎬💬 Projection et rencontre",
                description: "Projection et rencontre autour d'un des chapitres du film Le Daftar, 2023 de Vir Andres Hera, discussion et échanges avec Flash Our True Colors et Marie Lepetit.",
                place: "ESAD, 40 rue des Teinturiers, Amiens",
                schedule: "16h - 18h",
                img: projectionEtRencontre,
                imgAlt: "Illustration du film Le Daftar, film de Vir Andres Hera",
            }
        ]
    },
    {
        date: "Samedi 20 mai",
        color: "#750787",
        events: [
            {
                name: "🎉🌈 Soirée de clôture : Bal des Couleurs",
                description: `Retours en image sur le festival LGBTQIA+ avec une exposition, atelier Glow-Up de 19h à 20h en prix libre : avant de passer une soirée colorée, viens te faire maquiller et pailleter avec nous. Tu as le droit de briller ! Atelier Mode de 20h à 21h : pour montrer que tu es "the one" viens te préparer avec ta plus belle tenu et nous en mettre plein les yeux avec tes pas déterminés ! Atelier portrait de 20h à 21h : tu es glamour, c'est le moment de le fixer pour les générations futures. Qu'on se souvienne que tu es magnifique !`,
                place: "Cité Carter, 3 rue Georges Guynemer, Amiens",
                schedule: "19h - 23h",
                img: atelierGlowUp,
                imgAlt: "Atelier Glow-Up du festival IDAHOT",
            }
        ]
    }
]

export const programmation2024 = [
    {
        date: "Lundi 13 mai",
        color: "#e40303",
        events: [
            {
                name: "🎪 Stand associatif",
                description: "En partenariat avec le Crous Amiens-Picardie, nous serons présent⸱e⸱s au sein du Restaurant universitaire Saint-Leu. Viens nous rencontrer lors de ta pause déjeuner : nous serons disponibles pour échanger avec les étudiant⸱e⸱s et présenter le programme du festival !",
                place: "Restaurant universitaire Saint-Leu, 4 rue Vanmarcke, Amiens",
                schedule: "11h30 - 13h30",
                img: standCrous,
                imgAlt: "Stand de l'association Flash Our True Colors pendant le festival IDAHOT à Amiens"
            },
            {
                name: "🎭 Atelier : Théâtre de l'opprimé",
                description: "Avec l'association Pas à Passo, viens t'initier à la méthode du Théâtre de l'Opprimé dans la perspective de lutte pour les droits les personnes LGBTQIA+. Nous utiliserons la méthodologie du Théâtre de l'Oppprimé pour exprimer, analyser, mettre en scène les situations rencontrées par les participant⸱e⸱s dans leur quotidien.",
                place: "La Briqueterie, 2 rue Lescouvé, Amiens",
                schedule: "13h30 - 17h30",
                img: atelierOpprime,
                imgAlt: "Logo de l'association Pas à Passo spécialisée dans la méthode du théâtre de l'Opprimé",
                registration: "Sur inscription : contact@asso-fotc.fr"
            },
            {
                name: "🎈 Soirée d'inauguration",
                description: "Lors de la soirée d'inauguration du festival, nous invitons toutes les personnes qui le souhaitent à partager un moment convivial ensemble au sein du Café Côté Jardin. Nous aurons également l'occasion de vous présenter le programme et discuter de la programmation de cette année.",
                place: "Côté Jardin, 2 place Léon Gontier, Amiens",
                schedule: "19h - 21h",
                img: soireeInauguration2024,
                imgAlt: "Soirée d'inauguration à Côté Jardin pour le festival Hauts en Couleur d'Amiens"
            }
        ]
    },
    {
        date: "Mercredi 15 mai",
        color: "#F2D813",
        events: [
            {
                name: "🏈🏳️‍🌈 Conférence : Personnes LGBTQIA+ et sport",
                description: "La Fédération Sportive LGBT+ vous propose de venir partager un temps d'échange sur les personnes LGBTQIA+ et le sport, le tout de manière intéractive. Nous aurons notamment l'occasion de visionner le témoignage d'un militant et d'évoquer ce sujet sous l'angle des Jeux Olympiques et Paralympiques de Paris 2024 qui approchent.",
                place: "Atelier Canopé, 45 rue St Leu, Amiens",
                schedule: "14h - 17h",
                img: conferenceSport,
                imgAlt: "Plusieurs personnes courant avec chausettes aux couleurs de plusieurs drapeaux LGBTQIA+",
            },
            {
                name: "🎥 Ciné-débat : Rafiki",
                description: "Film sorti en 2018 originaire du Kenya et réalisé par Wanuri Kahiu, ce film évoque l'histoire de deux jeunes lycéennes qui tombent amoureuses dans un pays où l'homosexualité est illégale. L'occasion de s'interroger les conditions de vie des personnes LGBTQIA+ au Kenya. Le film sera suivi d'un temps d'échange en partenariat avec le Festival International du Film d'Amiens, le Cinéma Orson Welles et l'association Flash Our True Colors.",
                place: "Cinéma Orson Welles, 2 place Léon Gontier, Amiens",
                schedule: "20h00",
                img: cineDebatRafiki,
                imgAlt: "Affiche du film Rafiki",
                payable: "Tarifs du Cinéma Orson Welles"
            }
        ]
    },
    {
        date: "Jeudi 16 mai",
        color: "#008026",
        events: [
            {
                name: "🎪 Village associatif",
                description: "Au plus proche des étudiant⸱e⸱s, nous investissons le Pôle Universitaire Cathédrale (côté Bibliothèque) de l'Université de Picardie Jules Verne pour un temps d'échange avec diverses associations. L'opportunité de rencontrer les acteur⸱rice⸱s locaux qui luttent contre les discriminations et de découvrir les actions menées sur le territoire.",
                place: "Pôle Universitaire Cathédrale, placette Lafleur, Amiens",
                schedule: "11h - 14h",
                img: villageAssoUPJV,
                imgAlt: "Plusieurs militants pendant le village associatif avec des étudiants pendant le festival IDAHOT"
            },
            {
                name: "🎨 Atelier : Drag Poetry",
                description: "Par l'association Divergenre : Poète⸱sse, illustrateur⸱ice, les deux ou rien de tout ça ? Tu es le⸱a bienvenue pour partager quelques heures de ton temps avec nous ! Discussions et créations graphique, nous proposons un atelier artistique autour des esthétiques queer, qui a comme point de départ la poésie. Atelier ouvert à tous⸱tes, graine d’artiste ou professionnel⸱le.",
                place: "Le Corner, 12 rue des Majots, Amiens",
                schedule: "14h30 - 17h",
                img: atelierDragPoetry,
                imgAlt: "Illustration d'un recueil de poèmes et de dessins par Liso Liagre",
                registration: "Sur inscription : contact@asso-fotc.fr"
            },
        ]
    },
    {
        date: "Vendredi 17 mai",
        color: "#004dff",
        events: [
            {
                name: "🎤🏳️‍🌈 Rassemblement militant",
                description: "Comme chaque année, le festival propose un temps militant lors du 17 mai : journée mondiale contre l'homophobie, la transphobie et la biphobie ! Particulièrement en cette période de fortes violences LGBTQIA+phobes en France et à travers le monde, ce rassemblement revendicatif nous paraît plus que nécessaire, venez nombreux⸱ses !",
                place: "Place de l'Hôtel de Ville, Amiens",
                schedule: "18h - 19h",
                img: rassemblementRevendicatif,
                imgAlt: "Rassemblement contre les LGBTQIA+phobies à Amiens pour le 17 mai",
            },
        ]
    },
    {
        date: "Samedi 18 mai",
        color: "#750787",
        events: [
            {
                name: "🎉🌈 Soirée de clôture : Bal des Couleurs",
                description: `Soirée de clôture du Festival à Cité Carter : stands associatifs, musique et ateliers au rendez-vous. Concert et temps d'échange sur la transidentité avec le groupe La Reine Garçon, atelier Glow-Up (maquillage) de 19h à 20h en prix libre, défilé queer à partir de 20h00 avec élection de la queen ou du king de la soirée ! Viens ensuite danser avec nous pour clôturer le festival en beauté !`,
                place: "Cité Carter, 3 rue Georges Guynemer, Amiens",
                schedule: "18h - 23h",
                img: soireeBalDesCouleurs,
                imgAlt: "Plusieurs personnes qui défilent lors du défilé queer de la soirée Bal des Couleurs du Festival Hauts en Couleur à Amiens",
            }
        ]
    }
]

export const partners = [
    {
        img: villeamiens,
        alt: "Logo de la Ville d'Amiens",
        link: "https://www.amiens.fr"
    },
    {
        img: republiquefrancaise,
        alt: "Logo du Crous Amiens-Picardie",
        link: "https://www.crous-amiens.fr"
    },
    {
        img: crous,
        alt: "Logo du Crous Amiens-Picardie",
        link: "https://www.crous-amiens.fr"
    },
    {
        img: upjv,
        alt: "Logo de l'Université de Picardie Jules Verne",
        link: "https://www.u-picardie.fr"
    },
    {
        img: maisondelaculture,
        alt: "Logo de la Maison de la Culture d'Amiens",
        link: "https://www.maisondelaculture-amiens.com"
    },
    {
        img: fifam,
        alt: "Logo du Festival International du Film d'Amiens",
        link: "https://www.fifam.fr"
    },
    {
        img: citecarter,
        alt: "Logo de Cité Carter",
        link: "https://www.citecarter.com"
    },
    {
        img: pasapasso,
        alt: "Logo de l'association Pas à Passo Théâtre de l'Opprimé",
        link: "https://www.facebook.com/PasaPassoTO"
    },
    {
        img: divergenre,
        alt: "Logo de l'association Divergenre",
        link: "https://www.divergenre.fr"
    },
    {
        img: federationsportivelgbt,
        alt: "Logo de la Fédération Sportive LGBT+",
        link: "https://www.sports-lgbt.fr"
    },
    {
        img: flashourtruecolors,
        alt: "Logo de l'association Flash Our True Colors",
        link: "https://www.flash-our-true-colors.fr"
    },
]