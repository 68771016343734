import { Helmet } from "react-helmet"

import banner from '../../assets/pride/banniere-marche-des-fiertes-amiens-2023.jpg'
import marraine from '../../assets/pride/marraine-dorith-naon-marche-des-fiertes-amiens-1-juillet.jpg'
import planVillageAssociatif from '../../assets/pride/plan-village-associatif-marche-des-fiertes-amiens.png'
import soireeCloture from '../../assets/pride/soiree-de-cloture-marche-des-fiertes-amiens-red-and-white.png'

export default function PrideInfo2023() {
    return (
        <>
            <Helmet>
                <title>5ème Marche des Fiertés d'Amiens | 1 juillet 2023</title>
                <meta name="description" content="La Marche des Fiertés d'Amiens 2023 est le samedi 1 juillet, le village associatif ouvrira à 11h00 et le début de la Marche est à 14h00." />
            </Helmet>

            <section className="row justify-content-center blockPride pt-2 pb-3 pb-md-5">
                <div className="col-12 col-lg-8 pt-4">
                    <h1 className="text-center mb-2">5ème Marche des Fiertés d'Amiens</h1>
                    <h2 className="text-center mb-5">Samedi 1er juillet 2023</h2>
                    <p className="thasadith">
                        La 5ème édition de la Marche des Fiertés d'Amiens aura lieu le samedi 1er juillet 2023 ! Elle commémorera les 54 ans des émeutes de Stonewall.
                        Comme l'an dernier, la Marche sera accompagnée d'un village associatif afin de mettre en valeur les associations du territoire de lutte contre
                        toutes les formes de discriminations et pour la défense des droits des personnes Lesbiennes, Gays, Bi.e.s, Trans, Queer, Intersexuées
                        et plus (LGBTQI+) ainsi que des femmes à Amiens et ses environs.
                    </p>
                    <img src={banner} alt="Bannière de la Marche des Fiertés d'Amiens 2023" className="mt-2 img-fluid w-100"/>
                </div>
            </section>

            <section className="row justify-content-center blockPride pt-2 pb-3 pb-md-5">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-4 pt-2">Programme de la journée du 01 juillet</h2>
                    <ul className="pl-0">
                        <li className="mb-2 thasadith"><i className="fas fa-unlock pr-3"></i>11h - Ouverture du village associatif</li>
                        <li className="mb-2 thasadith"><i className="fas fa-hiking pr-4"></i>13h - Début des prises de paroles puis départ de la déambulation</li>
                        <li className="mb-2 thasadith"><i className="fas fa-microphone pr-4"></i>17h - Retour de la déambulation</li>
                        <li className="thasadith"><i className="fas fa-moon pr-4"></i>18h - Fermeture du village associatif</li>
                    </ul>
                </div>
            </section>

            <section className="row justify-content-center blockPride pt-2 pb-3 pb-md-4">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-2">Plan du village associatif</h2>
                    <div className="row px-3 px-md-0">
                        <div className="col-12 col-md-4 pl-md-0 pb-2">
                            <img src={planVillageAssociatif} alt="Plan du village associatif de la 5ème Marche des Fiertés d'Amiens" className="img-fluid"/>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center">
                            <ol className="mb-0 pl-0 pl-sm-3 pl-lg-5 thasadith">
                                <li className="mb-2">1 - Divergenre</li>
                                <li className="mb-2">2 - Famille au Grand Coeur</li>
                                <li className="mb-2">3 - Flash Our True Colors</li>
                                <li className="mb-2">4 - Fier.e.s et Queer</li>
                                <li className="mb-2">5 - David et Jonathan</li>
                                <li className="mb-2">6 - SOS Homophobie</li>
                                <li className="mb-2">7 - Collectif Lille Pride</li>
                                <li className="mb-2">8 - Amnesty International</li>
                            </ol>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center">
                            <ol className="mb-0 pl-0 pl-sm-3 pl-lg-5 thasadith">
                                <li className="mb-2">9 - Nous Toutes 80</li>
                                <li className="mb-2">10 - Planning Familial 80</li>
                                <li className="mb-2">11 - CFDT Somme</li>
                                <li className="mb-2">12 - Union départementale CGT</li>
                                <li className="mb-2">13 - FSU</li>
                                <li className="mb-2">14 - UNEF Amiens-Picardie</li>
                                <li className="mb-2">15 - ENIPSE</li>
                                <li className="mb-2">16 - Le MAIL</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center blockPride pt-2 pb-3 pb-md-5">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-4">Itinéraire</h2>
                    <div className="row px-3 px-md-0">
                        <div className="col-12 col-md-8 pt-3 pl-md-0">
                            <iframe width="100%" height="600px" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Itinéraire de la Marche des Fiertés" src="https://www.google.com/maps/embed?pb=!1m76!1m12!1m3!1d3634.849435897081!2d2.297610272503945!3d49.894547373041476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m61!3e2!4m5!1s0x47e7843ffa7aa67b%3A0x7c5413872f67d8a1!2sPlace%20L%C3%A9on%20Gontier%2C%20Amiens!3m2!1d49.8940713!2d2.2930696999999998!4m5!1s0x47e7844004671233%3A0xef487e8aa9c75268!2sRue%20de%20la%202%C3%A8me%20Division%20Blind%C3%A9e%2C%2080000%20Amiens!3m2!1d49.8930687!2d2.2927302!4m5!1s0x47e784406ad57817%3A0x4d9d240e9fad4cd2!2sRue%20de%20Beauvais%2C%2080000%20Amiens!3m2!1d49.8926842!2d2.2932711!4m5!1s0x47e784471a0dcde1%3A0x845396dbb2291164!2sRue%20des%20Jacobins%2C%20Amiens!3m2!1d49.891557!2d2.299673!4m5!1s0x47e78447ae59b6e9%3A0x86c5fcc9df2cb0c5!2sRue%20Allart%2C%20Amiens!3m2!1d49.8913022!2d2.3012181!4m5!1s0x47e78447ed670add%3A0xfa4dcd9e951ec57d!2sRue%20Victor%20Hugo%2C%20Amiens!3m2!1d49.892731399999995!2d2.3023314!4m5!1s0x47e7844802fe0685%3A0x6c7b9e11df84ac86!2s2%20Rue%20de%20l&#39;Oratoire%2C%20Amiens!3m2!1d49.8932108!2d2.3043947!4m5!1s0x47e78437ea401095%3A0x190b45a0e08e54ee!2sRue%20des%20Augustins%2C%20Amiens!3m2!1d49.8943029!2d2.3054601999999997!4m5!1s0x47e7843e8e65ac87%3A0x952609090a78ebb8!2sPlace%20Vogel%2C%20Amiens!3m2!1d49.897679499999995!2d2.2963467!4m5!1s0x47e7843ffa7aa67b%3A0x7c5413872f67d8a1!2sPlace%20L%C3%A9on%20Gontier%2C%20Amiens!3m2!1d49.8940713!2d2.2930696999999998!5e0!3m2!1sfr!2sfr!4v1680139183094!5m2!1sfr!2sfr"></iframe>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center pt-3">
                            <ol className="mb-0 pl-0 pl-sm-3 pl-lg-5 thasadith">
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Départ Place Léon Gontier</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue de la 2eme division Blindée</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue de Beauvais</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue Dumeril</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Jacobins</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue Allart</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue Victor Hugo</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Place Saint Michel</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue Adéodat Lefevre</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Augustins</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Place Parmentier</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue Vanmarcke</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Francs Mûriers</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Place Vogel</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Rue du Général Leclerc</li>
                                <li className="mb-2"><i className="fas fa-map-marker-alt mr-2"></i> Arrivée Place Léon Gontier</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center blockPride pt-2 pb-5 pb-md-5">
                <div className="col-12 col-lg-8">
                    <div className="row px-3 px-md-0 blockPride">
                        <div className="col-12 col-md-8 pt-3 pt-sm-5">
                            <h2 className="text-center mb-4">Mot de la marraine</h2>
                            <p className="thasadith">
                                Je suis l'ambassadrice de la fondation Le Refuge, qui héberge les jeunes LGBT+ mis à la rue par leur famille. J'ai + de 64 000 abonnés
                                sur Linkedin. Je prends la parole dessus pour lutter contre les LGBT+phobies, sensibiliser, éveiller les consciences et libérer la
                                parole. Et par dessus tout : inciter les dirigeants à (vraiment) agir pour la diversité et l'inclusion dans leur entreprise. Et passer un
                                message fort aux salariés, entrepreneurs, chercheurs d'emploi et à quiconque me lit : soyez toujours fier d'être vous mêmes. Une
                                entreprise qui vous discrimine sur votre identité ou votre apparence ne mérite ni votre temps ni votre énergie.
                            </p>
                            <p className="thasadith">
                                Dorith Naon
                            </p>
                        </div>
                        <div className="col-12 col-md-4 pt-3 pl-md-0">
                            <img src={marraine} alt="Dorith Naon, marraine de la 5ème Marche des Fiertés d'Amiens" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center blockPride pt-2 pb-5 pb-md-5">
                <div className="col-12 col-lg-8">
                    <div className="row px-3 px-md-0 blockPride">
                        <div className="col-12 col-md-6 pt-3 pl-md-0">
                            <img src={soireeCloture} alt="Affiche de la soirée officielle de clôture de la Marche des Fiertés d'Amiens 2023 au Red & White" className="img-fluid"/>
                        </div>
                        <div className="col-12 col-md-6 pt-3 pt-sm-5">
                            <h2 className="text-center mb-4">Soirée de clôture officielle</h2>
                            <p className="thasadith">
                                Une soirée de clôture de la Marche des Fiertés aura lieu au Red & White, bar LGBTQIA+ (9 rue de la Dodane à Amiens).
                            </p>
                            <p className="thasadith">
                                La soirée se déroulera de 18h00 à 02h00 : de 18h00 à 22h00 en extérieur puis ensuite en intérieur jusqu'à 2h00 !
                            </p>
                            <p className="thasadith">
                                Il y aura notamment une scène ouverte et une première partie Drag King par Signore Noam ! Puis ensuite des performances Drag avec Birdy Leen, Maxxie et Victoria Queen ainsi que des DJ sets avec Lucky et Wesley Elash !
                            </p>
                            <p className="thasadith">
                                Le tout préparé soigneusement par le Red & White et Lady Tayra.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}