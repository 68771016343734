import { Helmet } from "react-helmet"
import { EventsCard2024 } from "../../components/EventCard"
import { partners, programmation2024 } from "../../programmation"
import affiche from '../../assets/festival/2023/logo-festival-idahot-lgbt-amiens.png'

export default function FestivalInfo2024() {
    return (
        <>
            <Helmet>
                <title>Festival Hauts en Couleur 2024</title>
                <meta name="description" content="Nouvelle édition du festival Hauts en Couleur en 2024 à Amiens, un festival LGBTQIA+ pour lutter contre les discriminations."/>
            </Helmet>

            <section className="row justify-content-center purple pt-3">
                <div className="row col-11 col-lg-8">
                    <div className="col-12 col-xl-6 p-4">
                        <h1 className="text-center mb-2">Festival Hauts en Couleur (IDAHOT)</h1>
                        <h2 className="text-center mb-3">13 mai au 18 mai 2024</h2>
                        <img src={affiche} className="mt-4 img-fluid" alt="Logo du festival Hauts en Couleur d'Amiens"/>
                    </div>
                    <div className="col-12 col-xl-6 p-4">
                        <p className="thasadith" style={{color: "black"}}>
                            Le festival Hauts⸱en⸱Couleur revient pour une nouvelle édition 2024 ! Ce festival, organisé par <a target="_blank" rel="noreferrer" href="https://flash-our-true-colors.fr">Flash Our True Colors</a> depuis 2012 a pour but de lutter contre les discriminations faites aux personnes LGBTQIA+ (Lesbienne, Gay, Bi⸱e, Trans, Queer, Intersexe, Asexuel⸱le⸱s/Aromatiques/Agenre et tous⸱tes les autres), il commèmore entre autres le 17 mai : Journée Internationale de Lutte Contre l’Homophobie, la Transphobie et la Biphobie, appelée aussi IDAHOT en anglais (International Day Against Homophobia, Transphobia and Biphobia).
                            <br/><br/>Cette année, la programmation est encore plus diverse grâce aux nombreux partenaires qui nous ont rejoints avec un atelier théâtre de l'Opprimé, un atelier sur les créatures dessinées ou encore une conférence sur le sport et les personnes LGBTQIA+ !
                            <br/><br/>A travers ce programme, valorisons et revendiquons notre place dans le tissu social et culturel.  
                            <br/><br/>Le festival est ouvert à toute personne qui souhaite y prendre part ! Retrouvez le programme du festival ci-dessous.
                        </p>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center pt-5 pb-4">
                <div className="col-11 col-md-10">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <h2>Programme du festival</h2>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 pb-4">
                                <div className="col d-flex justify-content-center pt-2">
                                    <i className="fas fa-euro-sign fa-lg mt-1 mr-3"></i>
                                    <span>Payant</span>
                                </div>
                                <div className="col d-flex justify-content-center pt-2">
                                    <i className="fas fa-user-edit fa-lg mt-1 mr-3"></i>
                                    <span>Sur inscription</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        {programmation2024.map((day, index) => <EventsCard2024 data={day} key={index}/>)}
                    </div>
                </div>
            </section>

            <section className="row justify-content-center pt-5 pb-4">
                <div className="col-11 col-md-10">
                    <h2>Les partenaires du festival</h2>
                    <div className="row pt-3 pb-5">
                        {partners.map((partner, index) => (
                            <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-1" key={index}>
                                <a href={partner.link} target="_blank" rel="noreferrer">
                                    <img className="img-fluid" src={partner.img} alt={partner.alt}/>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}