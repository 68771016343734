import { Helmet } from "react-helmet"
import { Link } from "react-router-dom";

export default function NoMatch() {
    return (
        <div className="container pb-4 pt-5">
            <Helmet>
                <title>Page 404</title>
            </Helmet>
            <div className="row mb-3">
                <div className="col-12 col-sm-12 text-center shadow bg-danger text-light rounded">
                    <h1 className="mt-3">Oups ! tu as trouvé la page 404</h1>
                    <p>
                        Clique sur l'image revenir à l'accueil <i className="fas fa-arrow-circle-down"></i>
                    </p>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 p-0">
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/Marche2019/marche-2019-photo-14.jpg`} alt="Marche des Fiertés d'Amiens de 2019" className="img-fluid rounded"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}